import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {interval, of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {
  GetOverstayedCheckedInsAction,
  GetOverstayedCheckedInsSuccessAction,
  GetProfileAction,
  GetProfileSuccessAction,
  LoginAction,
  LoginAzureAction,
  ProfileActions,
  SaveProfilesAvatarAction,
  UpdateLoadingStatusAction
} from '../actions/profile.actions';
import {AuthService} from '../../services/auth/auth.service';
import {NotifierService} from 'angular-notifier';
import {ProfileState} from '../reducers/profile.reducer';

const url = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
  navigator.userAgent
)
  ? `${window.location.origin}/dashboard/prospects/agent-dashboard-visitors`
  : `${window.location.origin}/dashboard`;

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private auth: AuthService,
    private router: Router,
    private notifierService: NotifierService,
    private store: Store<{profile: ProfileState}>
  ) {}

  @Effect() logIn$ = this.actions$.pipe(
    ofType(ProfileActions.LoginAction),
    switchMap((action: LoginAction) => this.auth.login(action.payload)),
    map((value: {token?: string; refreshToken?: string; message?: string}) => {
      if (value.token) {
        localStorage.setItem('access_token', value.token);
        localStorage.setItem('refresh_token', value.refreshToken);
      }
    }),
    tap(() =>
      window.location.replace(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
          navigator.userAgent
        )
          ? `${window.location.origin}/dashboard/prospects/agent-dashboard-visitors`
          : `${window.location.origin}/dashboard`
      )
    ),
    map(() => new GetProfileAction()),
    catchError((err, caught) => caught)
  );

  @Effect() logInAzure$ = this.actions$.pipe(
    ofType(ProfileActions.LoginAzureAction),
    switchMap(() => this.auth.loginAzure()),
    catchError((err, caught) => caught)
  );

  @Effect() getProfile = this.actions$.pipe(
    ofType(ProfileActions.GetProfileAction),
    switchMap(() => this.auth.getProfile()),
    map((profile) => new GetProfileSuccessAction(profile)),
    catchError((err, caught) => caught)
  );

  @Effect() updateProfile = this.actions$.pipe(
    ofType(ProfileActions.UpdateProfileAction),
    switchMap(({payload}) => this.auth.saveProfile(payload)),
    tap((response: any) =>
      this.notifierService.notify('success', response.message)
    ),
    map(() => new GetProfileAction()),
    catchError((err, caught) => caught)
  );

  @Effect() saveProfilesAvatar = this.actions$.pipe(
    ofType(ProfileActions.SaveProfilesAvatar),
    switchMap((action: SaveProfilesAvatarAction) =>
      this.auth.saveAvatar(action.payload)
    ),
    tap((response: any) =>
      this.notifierService.notify('success', response.message)
    ),
    map(() => new GetProfileAction()),
    catchError((err, caught) => caught)
  );

  @Effect()
  fetchOverstayedCheckedIns$ = interval(60000).pipe(
    switchMap(() => of(new GetOverstayedCheckedInsAction()))
  );

  @Effect()
  getOverstayedCheckedIns$ = this.actions$.pipe(
    ofType(ProfileActions.GetOverstayedCheckedIns),
    withLatestFrom(this.store.select((state) => state.profile)),
    switchMap(([_, state]) =>
      this.auth.getOverstayAlerts().pipe(
        map((data: any) => {
          const isNewData = data.overstayedCheckedIns.some(
            (item: any) =>
              !state.overstayedCheckedIns.some(
                (oldItem) => oldItem.id === item.id
              )
          );

          if (
            state.firstFetchDone &&
            isNewData &&
            localStorage.getItem('Sound push') !== 'false'
          ) {
            const audio = new Audio();
            audio.src = '../assets/sounds/checkin.mp3';
            try {
              audio.load();
              audio.play();
            } catch (e) {}
          }

          return new GetOverstayedCheckedInsSuccessAction(
            data.overstayedCheckedIns
          );
        }),
        catchError((err) => of([]))
      )
    )
  );
}
