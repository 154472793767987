import {Injectable, Output} from '@angular/core';
import {ProspectsAgentDashboardService} from '../dashboard/prospects/services/prospects-agent-dashboard.service';

import firebase from 'firebase/app';
import 'firebase/messaging';

import {environment} from '../../environments/environment';
import {EventEmitter} from '@angular/core';
import {first} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {ProspectsAgentDashboardState} from '../dashboard/prospects/Store/reducers/prospects-agent-dashboard.reducer';
import * as moment from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';
import {ProfileState} from '../Store/reducers/profile.reducer';
import {UpdateMessagesAction} from '../Store/actions/profile.actions';

declare const Twilio: any;

@Injectable()
export class MessagingService {
  public client;
  public conversationArr = [];
  public conversationMessagesMap = new Map();
  public conversationParticipantsMap = new Map();
  public selectedConversationMap = new Map();
  public subscribedConversationsMap = new Map();
  public messagesBox = [];
  public countMess = [0];
  public _target;
  public firebaseApp;
  public messaging;
  public twilioLoading = false;
  private resolveTwilioClient;
  public twilioClient = new Promise<any>((resolve, reject) => {
    this.resolveTwilioClient = resolve;
  });
  private resolveFirebaseClient;
  private rejectFirebaseClient;
  public firebaseClient = new Promise<any>((resolve, reject) => {
    this.resolveFirebaseClient = resolve;
    this.rejectFirebaseClient = reject;
  });
  public pushMute: boolean;
  public profile;
  public userId;

  public healthCheck = new BehaviorSubject(false);

  public dateNow = moment();

  public pushToken;

  public interval;

  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor(
    private service: ProspectsAgentDashboardService,
    private store: Store<ProfileState>
  ) {
    this.initFirebase()
      .then(() => this.initClient())
      .catch((e) => this.initClient(false));
    /*if (this.messagesBox.length === 0) {
      let messagesArr = JSON.parse(localStorage.getItem('messagesBox'))
      this.messagesBox = messagesArr;
    }*/
    // let messagesObject = JSON.parse(localStorage.getItem('messagesBoxObject'))
    // this.messagesBoxObject = messagesObject;
    //messagesArr.filter(item => this.dateNow.diff(item.date, 'hours') < 2);
  }

  async requestPermission() {
    let resolvePermission;
    let rejectPermission;
    const requestPermission = new Promise<any>((resolve, reject) => {
      resolvePermission = resolve;
      rejectPermission = reject;
    });
    console.log('Requesting permission...');
    // [START request_permission]
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        resolvePermission();
      } else {
        console.log('Unable to get permission to notify.');
        rejectPermission();
      }
    });
    return requestPermission;
  }

  async initFirebase() {
    try {
      const {
        firebaseApiKey: apiKey,
        firebaseAuthDomain: authDomain,
        firebaseDatabaseURL: databaseURL,
        firebaseProjectId: projectId,
        firebaseStorageBucket: storageBucket,
        firebaseMessagingSenderId: messagingSenderId,
        firebaseAppId: appId,
        firebaseMeasurementId: measurementId,
        firebasePublicVapidKey: publicVapidKey
      } = environment;
      this.firebaseApp = await firebase.initializeApp(
        {
          apiKey,
          authDomain,
          databaseURL,
          projectId,
          storageBucket,
          messagingSenderId,
          appId,
          measurementId
        },
        'twilioChat'
      );
      this.messaging = this.firebaseApp.messaging();
      await this.requestPermission();
      this.messaging.usePublicVapidKey(publicVapidKey);
      this.messaging.onMessage(this.handlePush.bind(this));
      this.setHealth(true);
      this.resolveFirebaseClient(this.messaging);
    } catch (e) {
      console.error('Firebase init error', e.message);
      this.rejectFirebaseClient();
    }
  }

  async initClient(firebase = true) {
    this.service
      .getToken()
      .pipe(first())
      .subscribe(async (data: {token}) => {
        if (firebase) {
          const fbToken = await this.messaging.getToken();
          await this.initTwilioChat(data.token, fbToken);

          await this.service.getPushToken(fbToken).pipe(first()).subscribe();
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            this.service.getPushToken(fbToken).pipe(first()).subscribe();
          }, 60000);
          this.pushToken = fbToken;
        } else {
          await this.initTwilioChat(data.token);
        }
        this.twilioLoading = true;
        return this.resolveTwilioClient(this.client);
      });
  }

  async initTwilioChat(twilioToken, fbToken = null) {
    this.client = await Twilio.Conversations.Client.create(twilioToken);
    this.client.on('conversationAdded', this.conversationAdded);
    this.client.on('tokenAboutToExpire', this.updateToken);
    this.client.on('tokenExpired', this.updateToken);
    if (fbToken) {
      this.client.setPushRegistrationId('fcm', fbToken);
    }
    const {items} = await this.client.getSubscribedConversations();
    this.conversationArr = items;
    await Promise.all(
      items.map(async (item) => {
        try {
          if (
            item.lastMessage &&
            item.lastMessage.index &&
            item.lastReadMessageIndex &&
            item.lastReadMessageIndex !== item.lastMessage.index
          ) {
            return this.getLastMessage(item);
          } else if (
            !item.lastReadMessageIndex &&
            item.lastMessage &&
            item.lastMessage.index
          ) {
            return item.lastMessage.index;
          } /*else if (!item.state.lastReadMessageIndex && !item.state.lastMessage && !item.state.lastMessage.index)
         {
           return;
         }*/
        } catch (e) {
          console.error('Push error: ', e.message);
        }
        return Promise.resolve();
      })
    );
  }

  async initConversation(sid) {
    await this.initTwilioClient();
    if (!this.client) {
      await this.initClient();
    }
    if (this.selectedConversationMap.has(sid)) {
      return this.selectedConversationMap.get(sid);
    }

    const currentConversation = await this.client.getConversationBySid(sid);
    currentConversation.on('messageAdded', this.messageAddedListener);
    currentConversation.on('updated', this.updateListener);
    currentConversation.on('typingStarted', this.typingStarted);
    currentConversation.on('typingEnded', this.typingEnded);
    currentConversation.on('participantJoined', this.participantJoinListener);
    currentConversation.on('participantLeft', this.participantLeft);
    return currentConversation;
  }

  private conversationAdded() {
    console.log('Conversation added');
  }

  private typingStarted() {
    console.log('Typing started');
  }

  private typingEnded() {
    console.log('Typing Ended');
  }

  public participantJoinListener = this.participantJoined.bind(this);

  public async participantJoined(participant) {
    // console.log(participant);
    const conversation = this.selectedConversationMap.get(
      participant.conversation.sid
    );
    //const [participants] = await Promise.all([conversation.getParticipants()]);
    //participants.map((u) => (u));
    // this.conversationMessagesMap.set(conversation.sid, messages.items);
    //console.log(this.conversationParticipantsMap.get(conversation.sid))
    const getParticipant = this.conversationParticipantsMap.get(
      conversation.sid
    );
    getParticipant.push(participant);
    this.conversationParticipantsMap.set(conversation.sid, getParticipant);
    //console.log(this.conversationParticipantsMap.get(conversation.sid))
    //console.log(this.conversationParticipantsMap.get(conversation.sid).push(participant))

    //this.conversationMessagesMap.get(conversation.sid).map((m) => this.mess(m, participants));
    console.log('Participant Joined');
  }

  private participantLeft() {
    console.log('Participant Left');
  }

  private messageAddedListener = this.messageAdded.bind(this);

  private async messageAdded(m) {
    const conversation = this.selectedConversationMap.get(m.conversation.sid);
    if (!conversation) {
      return;
    }
    if (
      this.conversationMessagesMap
        .get(conversation.sid)
        .findIndex(({state}) => state.sid === m.state.sid) === -1
    ) {
      this.conversationMessagesMap
        .get(conversation.sid)
        .push(
          this.mess(m, this.conversationParticipantsMap.get(conversation.sid))
        );
    }
    console.log('Message added');
  }

  private mess(m, participants) {
    m.user = participants.find((u) => u.identity.localeCompare(m.author) === 0);
    if (m.state.author === 'walk.in') {
      const checkInMessage =
        'Welcome to +SelfTour. Use this chat to contact agent if you have any questions.';
      if (m.state.body === checkInMessage) {
        m.state.body = `${m.conversation.state.createdBy} checked in ${m.conversation.state.attributes.unitId}.`;
      }
    }
    /*if (m.user === undefined) {
      m.user = {
        state: {
          attributes: {
            name: 'Bot',
            avatarUrl: '.'
          }
        }
      }
    }*/
    return m;
  }

  async getAllMess(sid) {
    const conversation = this.selectedConversationMap.get(sid);
    const [messages, participants] = await Promise.all([
      conversation.getMessages(300),
      conversation.getParticipants()
    ]);
    //participants.map((u) => (u));
    this.conversationMessagesMap.set(sid, messages.items);
    this.conversationParticipantsMap.set(sid, participants);
    this.conversationMessagesMap
      .get(sid)
      .map((m) => this.mess(m, participants));
  }

  public async getCurrentConversationMessages(sid) {
    if (!this.conversationMessagesMap.has(sid)) {
      await this.getAllMess(sid);
    }
    return this.conversationMessagesMap.get(sid);
  }

  async getLastMessage(conversation) {
    let message;
    try {
      const {
        items: [{conversation: c, body, author}]
      } = await conversation.getMessages(1);
      const [, visId, apId] = conversation.channelState.uniqueName.split('=');
      const visitorId = visId.replace(/[^\d]/g, '');
      let apartmentId;
      if (apId) {
        apartmentId = apId.replace(/[^\d]/g, '');
      } else {
        apartmentId = null;
      }
      let unreadMess;
      if (!c.lastReadMessageIndex) {
        unreadMess = c.lastMessage.index;
      } else {
        unreadMess = c.lastMessage.index - c.lastReadMessageIndex;
      }
      const conversationTitle = c.channelState.uniqueName;
      const {
        attributes: {name: authorName}
      } = await this.client.getUser(author);
      const address = c.channelState.friendlyName;
      message = {
        author: authorName,
        address,
        mess: body,
        sid: c.sid,
        visitorId,
        apartmentId,
        conversationTitle,
        unreadMess
      };
    } catch (e) {
      console.error('Get Last Message Error:', e.message);
    }

    if (message) {
      this.messagesBox.unshift(message);
      this.countMess[0] = this.messagesBox.length;
    }
    //return this.messagesBoxObject;
    this.store.dispatch(new UpdateMessagesAction(this.messagesBox));
    return [this.messagesBox, this.countMess];
  }

  private updateToken = this.updatedToken.bind(this);

  updatedToken() {
    this.service
      .getToken()
      .pipe(first())
      .subscribe(async (data: {token}) => {
        await this.client.updateToken(data.token);
      });
  }

  private updateListener = this.updatedAttr.bind(this);

  updatedAttr(conversation) {}

  public getPush() {
    if (!this.firebaseApp) {
      console.error('Firebase not initialise');
    }
    //return this.messagesBoxObject
    this.store.dispatch(new UpdateMessagesAction(this.messagesBox));
    return [this.messagesBox, this.countMess];
  }

  async handlePush(payload: {data: any}) {
    try {
      this.pushMute = localStorage.getItem('Sound push') !== 'false';
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.setHealth(true);
      if (payload.data.title === 'WEB_PUSH_HEALTH_CHECK') {
        return;
      }
      if (this.pushMute) {
        let audio = new Audio();
        if (
          payload.data &&
          payload.data.twi_message_type === 'twilio.conversations.new_message'
        ) {
          audio.src = '../assets/sounds/message.mp3';
        } else {
          audio.src = '../assets/sounds/checkin.mp3';
        }
        try {
          audio.load();
          await audio.play();
        } catch (e) {}
      }
      if (
        payload.data &&
        payload.data.twi_message_type === 'twilio.conversations.new_message'
      ) {
        await this.handleTwilioPush(payload);
      } else {
        await this.handleWebPush(payload);
      }
      // this.showPush();
    } catch (e) {
      console.error('Push Notifications error: ', e.message);
    }
  }

  getHealth(): Observable<boolean> {
    return this.healthCheck;
  }

  setHealth(state): void {
    this.healthCheck.next(state);
  }

  async handleTwilioPush(payload: {data: any}) {
    if (this.client) {
      this.client.handlePushNotification(payload);
    }
    if (payload && payload.data && payload.data.conversation_sid) {
      const data = payload.data;
      const {conversation_sid: sid, author} = data;
      if (
        data.twi_message_type === 'twilio.conversations.new_message' &&
        data.twi_body &&
        data.conversation_title
      ) {
        const conversationTitle = data.conversation_title;
        const messageBody = data.twi_body.split(';');
        const [, visId, apId] = data.conversation_title.split('=');
        const visitorId = visId.replace(/[^\d]/g, '');
        let apartmentId;
        if (apId.replace(/[^\d]/g, '')) {
          apartmentId = apId.replace(/[^\d]/g, '');
        } else {
          apartmentId = null;
        }
        const date = this.dateNow;
        if (author === 'walk.in') {
          const currentConversation =
            this.conversationArr.find((item) => item.sid === sid) ||
            (await this.client.getConversationBySid(sid));
          const mess = `${currentConversation.state.createdBy} checked in ${currentConversation.state.attributes.unitId}`;
          this.messagesBox.unshift({mess, visitorId, apartmentId, sid, date});
        } else {
          const [address, mess] = messageBody;
          const index = this.messagesBox.findIndex((item) => item.sid === sid);
          const unreadMess =
            index !== -1 ? this.messagesBox[index].unreadMess + 1 : 1;
          if (index !== -1) {
            this.messagesBox.splice(index, 1);
          }
          this.messagesBox.unshift({
            author,
            address,
            mess,
            sid,
            visitorId,
            apartmentId,
            conversationTitle,
            unreadMess,
            date
          });
        }
        this.setLocaleStorage(this.messagesBox);
        this.countMess[0] = this.messagesBox.length;
        //return this.messagesBoxObject;
        this.store.dispatch(new UpdateMessagesAction(this.messagesBox));
        return [this.messagesBox, this.countMess];
      }
    }
  }

  async handleWebPush(payload) {
    if (payload && payload.data && payload.data.conversationSid) {
      const data = payload;
      const sid = data.data.conversationSid;
      const mess = data.data.body;
      const title = data.data.title;
      const visitorId = data.data.visitorId;
      const apartmentId = data.data.apartmentId;
      const date = this.dateNow;
      this.messagesBox.unshift({
        sid,
        mess,
        title,
        visitorId,
        apartmentId,
        date
      });
      this.countMess[0] = this.messagesBox.length;
      this.setLocaleStorage(this.messagesBox);
      //return this.messagesBoxObject;
      this.store.dispatch(new UpdateMessagesAction(this.messagesBox));
      return [this.messagesBox, this.countMess];
    }
  }

  setLocaleStorage(messagesBox) {
    localStorage.setItem('messagesBox', JSON.stringify(messagesBox));
  }

  public pushMuted() {
    this.pushMute = !this.pushMute;
    return this.pushMute;
  }

  get messageBox() {
    //return this.messagesBoxObject
    //this.store.dispatch(new UpdateMessagesAction(this.messagesBox));
    return this.messagesBox;
  }

  get target() {
    return this._target;
  }

  set target(message) {
    this._target = message;
  }

  public toggle() {
    this.change.emit(this._target);
  }

  async initTwilioClient() {
    return await this.twilioClient;
  }

  async getUnreadMess(sid) {
    try {
      let conversation = await this.client.getConversationBySid(sid);
      let unreadMessages = 0;
      if (
        conversation.lastMessage.index !== conversation.lastReadMessageIndex
      ) {
        unreadMessages =
          conversation.lastMessage.index - conversation.lastReadMessageIndex;
      }
      conversation = null;
      return unreadMessages;
    } catch (e) {
      return 0;
    }
  }

  deleteCurrentPush(sid) {
    this.messagesBox = this.messageBox.filter(
      (item) => item.conversationTitle !== sid
    );
    this.countMess[0] = this.messagesBox.length;
    this.setLocaleStorage(this.messagesBox);
    this.store.dispatch(new UpdateMessagesAction(this.messagesBox));
    return [this.messagesBox, this.countMess];
    //delete this.messagesBoxObject[sid]
  }

  unsubscribePush() {
    try {
      this.client.unsetPushRegistrationId('fcm');
    } catch (e) {
      console.error('error: ', e.message);
    }
  }

  async selectConversation(conversationSid) {
    if (!this.selectedConversationMap.has(conversationSid)) {
      this.selectedConversationMap.set(
        conversationSid,
        await this.initConversation(conversationSid)
      );
    }
    return this.selectedConversationMap.get(conversationSid);
  }

  public async leaveCurrentConversation(conversationSid) {
    const leftConversation = this.selectedConversationMap.get(conversationSid);
    if (!leftConversation) {
      return;
    }
    console.log('left ' + leftConversation.friendlyName);
    leftConversation.removeListener('messageAdded', this.messageAddedListener);
    leftConversation.removeListener('updated', this.updateListener);
    leftConversation.removeListener('typingStarted', this.typingStarted);
    leftConversation.removeListener('typingEnded', this.typingEnded);
    leftConversation.removeListener(
      'participantJoined',
      this.participantJoinListener
    );
    leftConversation.removeListener('participantLeft', this.participantLeft);

    this.selectedConversationMap.delete(conversationSid);
    this.conversationMessagesMap.delete(conversationSid);
    this.conversationParticipantsMap.delete(conversationSid);
  }

  async sendMess(msg, sid) {
    let conversation = this.selectedConversationMap.get(sid);
    if (!this.client) {
      this.client = await this.initTwilioClient();
    }
    try {
      await conversation.sendMessage(msg);
    } catch (e) {
      console.error(e.message);
      try {
        await this.reconnectClient();
        conversation = this.selectedConversationMap.get(sid);
        await conversation.sendMessage(msg);
      } catch (e) {
        console.error(
          'Please check your internet connection and refresh the page.',
          e.message
        );
      }
    }
    conversation.advanceLastReadMessageIndex(conversation.lastMessage.index);
    conversation.setAllMessagesRead();
  }

  async reconnectClient() {
    this.client.removeListener('conversationAdded', this.conversationAdded);
    this.client.removeListener('tokenAboutToExpire', this.updateToken);
    this.client.removeListener('tokenExpired', this.updateToken);
    for (let sid of this.selectedConversationMap.keys()) {
      await this.leaveCurrentConversation(sid);
    }
    this.conversationArr = [];
    this.client = await this.initTwilioClient();
  }

  deletePushToken() {
    if (!this.pushToken) {
      return;
    }
    this.service.deletePushToken(this.pushToken).subscribe();
  }
}
