import {ProfileActions, ProfileActionsUnion} from '../actions/profile.actions';

export interface ProfileState {
  profile: any;
  messagesBox: any;
  countMess: any;
  loading: boolean;
  overstayedCheckedIns: any[];
  firstFetchDone: boolean;
}

const InitialProfileState: ProfileState = {
  profile: null,
  loading: false,
  messagesBox: [],
  countMess: 0,
  overstayedCheckedIns: [],
  firstFetchDone: false
};

export function profileReducer(
  state = InitialProfileState,
  action: ProfileActionsUnion
) {
  switch (action.type) {
    case ProfileActions.UpdateMessagesActions: {
      return {
        ...state,
        messagesBox: action.payload,
        countMess: action.payload.length
      };
    }
    case ProfileActions.UpdateLoadingStatus: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case ProfileActions.GetProfileSuccessAction: {
      return {
        ...state,
        profile: action.payload
      };
      break;
    }
    case ProfileActions.GetOverstayedCheckedInsSuccess: {
      return {
        ...state,
        overstayedCheckedIns:
          action.payload && action.payload.length
            ? action.payload.map((item: any) => ({
                ...item,
                isOverstayAlert: true
              }))
            : [],
        firstFetchDone: true
      };
    }
    case ProfileActions.RemoveOverstayedCheckedIn: {
      return {
        ...state,
        overstayedCheckedIns: state.overstayedCheckedIns.filter(
          (item) => item.id !== action.payload
        )
      };
    }
    default:
      return state;
  }
}
