import { ApiService } from '../../../services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/api.service";
const url = 'prospects/agent-dashboard';
const apartments = 'apartments';
export class ProspectsNewAgentDashboardService {
    constructor(api) {
        this.api = api;
        this.getVisitors = (query = { page: 1, limit: 10 }) => this.api.call('GET', `${url}/v2/events`, null, query);
        this.getApartments = ({ id, query }) => this.api.call('GET', `${url}/v2/user/${id}/events/history`, null, query);
        this.getVisitor = (id) => this.api.call('GET', `${url}/v2/user/${id}/events/history`);
        this.getToken = () => this.api.call('POST', 'chat/token', {
            deviceId: 'desktop',
            deviceType: 'Web'
        });
        this.createChat = (userId, userIdentity, apartmentId) => this.api.call('POST', 'chat/create', {
            userId: userId,
            userIdentity: userIdentity,
            apartmentId: apartmentId
        });
        this.getStatistic = () => this.api.call('GET', `${url}/${apartments}/statistic`);
        this.getCheckinChats = () => this.api.call('GET', `${url}/self-tour/`);
        this.getActiveChats = () => this.api.call('GET', `${url}/v2/chats/active`);
        this.disableChat = (sid, unitId, mute) => this.api.call('POST', 'chat/update', {
            conversationSid: sid,
            unitId: unitId,
            muted: mute
        });
        this.approveOverstay = (id) => this.api.call('POST', `prospects/agent-dashboard/v2/overstay/approve/${id}`);
    }
}
ProspectsNewAgentDashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProspectsNewAgentDashboardService_Factory() { return new ProspectsNewAgentDashboardService(i0.ɵɵinject(i1.ApiService)); }, token: ProspectsNewAgentDashboardService, providedIn: "root" });
