import * as i0 from "./overstay-instructions.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./overstay-instructions.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../dashboard/prospects/services/prospects-new-agent-dashboard.service";
var styles_OverstayInstructionsComponent = [i0.styles];
var RenderType_OverstayInstructionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverstayInstructionsComponent, data: {} });
export { RenderType_OverstayInstructionsComponent as RenderType_OverstayInstructionsComponent };
export function View_OverstayInstructionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "confirmation-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Instructions"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "modal-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "btn-wrap one-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "btn btn-sm btn-green"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.options == null) ? null : _co.options.message); _ck(_v, 5, 0, currVal_0); }); }
export function View_OverstayInstructionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-overstay-instructions", [], null, null, null, View_OverstayInstructionsComponent_0, RenderType_OverstayInstructionsComponent)), i1.ɵdid(1, 245760, null, 0, i2.OverstayInstructionsComponent, [i3.Router, i4.ProspectsNewAgentDashboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverstayInstructionsComponentNgFactory = i1.ɵccf("app-overstay-instructions", i2.OverstayInstructionsComponent, View_OverstayInstructionsComponent_Host_0, {}, {}, []);
export { OverstayInstructionsComponentNgFactory as OverstayInstructionsComponentNgFactory };
