import {Action} from '@ngrx/store';

export enum ProfileActions {
  GetProfileAction = '[Login Page] GetProfile',
  LoginAction = '[Login Page] Login',
  LoginAzureAction = '[Login Page] Login Azure',
  GetProfileSuccessAction = '[App Login Check] GotProfile',
  UpdateProfileAction = '[Profile Page] UpdateProfile',
  SaveProfilesAvatar = '[Profile Page] Save Profiles Avatar',
  UpdateLoadingStatus = '[Profile Page] Update Loading Status',

  UpdateMessagesActions = '[Messages] UpdateMessages',

  GetOverstayedCheckedIns = '[Overstayed] GetOverstayedCheckedIns',
  GetOverstayedCheckedInsSuccess = '[Overstayed] GetOverstayedCheckedInsSuccess',
  RemoveOverstayedCheckedIn = '[Overstayed] RemoveOverstayedCheckedIn'
}

export class GetProfileAction implements Action {
  readonly type = ProfileActions.GetProfileAction;
}

export class GetProfileSuccessAction implements Action {
  readonly type = ProfileActions.GetProfileSuccessAction;

  constructor(public payload: any) {}
}

export class LoginAction implements Action {
  readonly type = ProfileActions.LoginAction;

  constructor(public payload: any) {}
}
export class LoginAzureAction implements Action {
  readonly type = ProfileActions.LoginAzureAction;

  constructor(public payload: any) {}
}

export class UpdateProfileAction {
  readonly type = ProfileActions.UpdateProfileAction;

  constructor(public payload: any) {}
}

export class SaveProfilesAvatarAction {
  readonly type = ProfileActions.SaveProfilesAvatar;

  constructor(public payload: FormData) {}
}

export class UpdateMessagesAction implements Action {
  readonly type = ProfileActions.UpdateMessagesActions;

  constructor(public payload: any) {}
}

export class UpdateLoadingStatusAction {
  readonly type = ProfileActions.UpdateLoadingStatus;

  constructor(public payload: boolean) {}
}

export class GetOverstayedCheckedInsAction implements Action {
  readonly type = ProfileActions.GetOverstayedCheckedIns;
}

export class GetOverstayedCheckedInsSuccessAction implements Action {
  readonly type = ProfileActions.GetOverstayedCheckedInsSuccess;
  constructor(public payload: any) {}
}

export class RemoveOverstayedCheckedInAction implements Action {
  readonly type = ProfileActions.RemoveOverstayedCheckedIn;
  constructor(public payload: number) {} // id записи
}

export type ProfileActionsUnion =
  | GetProfileAction
  | LoginAction
  | GetProfileSuccessAction
  | UpdateProfileAction
  | SaveProfilesAvatarAction
  | UpdateMessagesAction
  | UpdateLoadingStatusAction
  | GetOverstayedCheckedInsAction
  | GetOverstayedCheckedInsSuccessAction
  | RemoveOverstayedCheckedInAction;
