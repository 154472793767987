import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {QueryParamsInterface} from '../../../interfaces/query-params.interface';

const url = 'prospects/agent-dashboard';
const apartments = 'apartments';

@Injectable({
  providedIn: 'root'
})
export class ProspectsNewAgentDashboardService {
  constructor(private api: ApiService) {}

  public getVisitors = (query: QueryParamsInterface = {page: 1, limit: 10}) =>
    this.api.call('GET', `${url}/v2/events`, null, query);
  public getApartments = ({id, query}: any) =>
    this.api.call('GET', `${url}/v2/user/${id}/events/history`, null, query);
  public getVisitor = (id: number) =>
    this.api.call('GET', `${url}/v2/user/${id}/events/history`);

  public getToken = () =>
    this.api.call('POST', 'chat/token', {
      deviceId: 'desktop',
      deviceType: 'Web'
    });
  public createChat = (userId, userIdentity, apartmentId) =>
    this.api.call('POST', 'chat/create', {
      userId: userId,
      userIdentity: userIdentity,
      apartmentId: apartmentId
    });

  public getStatistic = () =>
    this.api.call('GET', `${url}/${apartments}/statistic`);

  public getCheckinChats = () => this.api.call('GET', `${url}/self-tour/`);
  public getActiveChats = () => this.api.call('GET', `${url}/v2/chats/active`);

  public disableChat = (sid, unitId, mute) =>
    this.api.call('POST', 'chat/update', {
      conversationSid: sid,
      unitId: unitId,
      muted: mute
    });

  public approveOverstay = (id) =>
    this.api.call(
      'POST',
      `prospects/agent-dashboard/v2/overstay/approve/${id}`
    );
}
