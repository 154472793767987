import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {
  ModalOptionsInterface,
  OverstayInstructionsModalOptionsInterface
} from '../../interfaces/modal.interface';
import {ModalService} from '../../services/modal.service';
import {Router} from '@angular/router';
import {ProspectsNewAgentDashboardService} from '../../../dashboard/prospects/services/prospects-new-agent-dashboard.service';

@Component({
  selector: 'app-overstay-instructions',
  templateUrl: './overstay-instructions.component.html',
  styleUrls: ['./overstay-instructions.component.sass']
})
export class OverstayInstructionsComponent implements OnInit, OnDestroy {
  public events: Subject<any> = new Subject<any>();
  public options: OverstayInstructionsModalOptionsInterface;
  public modalService: ModalService;

  constructor(
    private router: Router,
    private newAgentDashboardService: ProspectsNewAgentDashboardService
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.events = null;
  }

  public cancel = () => {
    this.newAgentDashboardService
      .approveOverstay(this.options.eventId)
      .subscribe();
    this.events.next(false);
    this.modalService.closeModal();
  };
}
