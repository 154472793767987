<div class="confirmation-modal">
  <div class="content-wrapper">
    <p class="modal-title">Instructions</p>
    <div class="modal-text">
      {{ options?.message }}
    </div>
    <div class="btn-wrap one-button">
      <button type="button" (click)="cancel()" class="btn btn-sm btn-green">
        <span>Ok</span>
      </button>
    </div>
  </div>
</div>
